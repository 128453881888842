//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'HelpDisabledRuCountry',

  props: {
    bottom: {
      type: Boolean,
      default: false
    }
  }
}
